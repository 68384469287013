<template>
    <v-row class="mt-4" justify="center">
        <v-col v-if="action === 1" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    v-if="projectStatus_Success"
                    flat
                    class="builder__container"
                    color="white"
                >
                    <v-card-text>
                        <project-name
                            :project-name.sync="project.title"
                            :in-valid.sync="invalidName"
                            :name="project.title"
                            :label="$t(`builder.components.title`)"
                        ></project-name>
                        <select-project-media
                            :project="project"
                            :file.sync="project.media"
                            :file-type.sync="fileType"
                            :in-valid.sync="invalidMedia"
                            :type="+buildType"
                        ></select-project-media>
                        <!-- <project-description
                            v-if="showDescription"
                            :project-description.sync="project.description"
                            :in-valid.sync="invalidDescription"
                            :description="project.description"
                            :label="$t(`builder.components.description`)"
                        ></project-description> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            :disabled="projectInvalid"
                            @click="action++"
                        >
                            {{ $t(`builder.actions.next`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-else>
            <transition name="fade-transform" mode="out-in">
                <v-card
                    flat
                    class="d-flex flex-column align-center centered"
                    color="appBackground"
                >
                    <v-card-text class="d-flex flex-column align-center">
                        <hero :size="140"></hero>
                        <div class="mt-6">
                            <h2>{{ $t(`builder.finishTxt`) }}</h2>
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="updateAsDraft()"
                        >
                            {{ $t(`builder.actions.save`) }}
                        </v-btn>
                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="updateAsPublish()"
                        >
                            {{ $t(`builder.actions.publish`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getLibrary, editLibrary } from "@/api/libraryApi.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { mapState } from "vuex";

import ProjectName from "@/components/Builder/ProjectName.vue";
import ProjectDescription from "@/components/Builder/ProjectDescription.vue";
import AddIngredients from "@/components/Builder/AddIngredients.vue";
import Hero from "@/components/Heroes/Hero.vue";
import SelectProjectMedia from "@/components/Builder/SelectProjectMedia.vue";
import EditStepper from "@/components/Builder/EditStepper.vue";

export default {
    name: "EditLibrary",

    components: {
        ProjectName,
        ProjectDescription,
        AddIngredients,
        SelectProjectMedia,
        Stepper: () => import("@/components/Builder/Stepper.vue"),
        StepChips: () => import("@/components/Builder/StepChips.vue"),
        StepImage: () => import("@/components/Builder/StepImage.vue"),
        EditStepper,
        Hero,
    },

    data() {
        return {
            fileType: null,
            project: {},
            projectSteps: [],
            media: null,
            action: 1,
            invalidMedia: false,
            invalidName: false,
            invalidDescription: false,
            buildType: null,

            projectStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["projectStatus"]),
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        showDescription() {
            if (this.fileType == "youtube_url") {
                return false;
            }
            return true;
        },

        projectInvalid() {
            return this.invalidMedia || this.invalidName;
        },
    },

    methods: {
        async handleProject() {
            this.projectStatus = apiStatus.Pending;
            const payload = {
                id: this.$route.params.id,
            };
            const { response, error } = await withAsync(getLibrary, payload);

            if (error) {
                this.projectStatus = apiStatus.Error;
                return;
            }

            this.project = response.data.data;
            if (this.project.library_file)
                this.media = this.project.library_file;
            this.project.media = this.project.library_file;
            this.projectSteps = this.project?.steps;

            this.projectStatus = apiStatus.Success;
        },

        async createWithImage(status) {
            this.createProjectStatus = apiStatus.Pending;

            let data = new FormData();
            data.append("title", this.project.title);
            data.append("library_file", this.project.media);
            data.append("status", status);

            const payload = {
                id: this.project.id,
                data: data,
            };

            const { response, error } = await withAsync(editLibrary, payload);

            if (error) {
                this.createProjectStatus = apiStatus.Error;
                return;
            }
            this.createProjectStatus = apiStatus.Success;
        },

        async createWithVideo(status) {
            if (this.fileType == "youtube_url") {
                this.project.youtube_url = this.media;
            }
            const data = {
                title: this.project.title,
                youtube_url: this.project.youtube_url,
                ingredients: this.project.ingredients,
                status: status,
            };

            const payload = {
                id: this.project.id,
                data: data,
            };

            const { response, error } = await withAsync(editLibrary, payload);

            if (error) {
                this.createProjectStatus = apiStatus.Error;
                return;
            }
            this.createProjectStatus = apiStatus.Success;
        },

        async updateAsDraft() {
            let status = 0;
            console.log(this.project);
            let project;
            this.fileType == "image_url"
                ? (project = await this.createWithImage(status))
                : (project = await this.createWithVideo(status));
            this.$router.push({ name: "MyProjects" });
        },

        async updateAsPublish() {
            let status = 1;
            this.fileType == "image_url"
                ? this.createWithImage(status)
                : this.createWithVideo(status);
            this.$router.push({ name: "MyProjects" });
        },
    },

    watch: {
        media(newVal) {},
    },

    created() {
        this.buildType = 4;
        this.handleProject();
    },
};
</script>

<style scoped></style>
